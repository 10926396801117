import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/icon/iconfont/iconfont.css"
import '@vant/touch-emulator'; //模拟手机事件

import './assets/basis_css/index.less';
import './assets/gzh.less'
import Vant from 'vant';
// import formCreate from '@form-create/naive-ui'

import {ElTree, ElPopover, ElScrollbar, ElSlider} from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import _ from "lodash";

_.set(window, 'jin_ru_page', location.href) //进来页面

const app = createApp(App)
app.use(ElTree)
    .use(ElScrollbar)
    .use(ElPopover)
    .use(ElSlider)
    .use(Vant)
    .use(store)
    .use(router)
app.mount('#app')
