import {createStore} from 'vuex'

// @ts-ignore
// @ts-ignore
// @ts-ignore
export default createStore({
    state: {
        listSelect: {
            //联系人
            //type:[{}]
        }
    },
    actions: {
        //触发器
        /**
         * 添加多选列表
         * @param ctx
         * @param val
         */
        //     addListSelect:function (ctx,val) {
        //         //触发自定义修改器
        //         //@ts-ignore
        //         ctx.commit('eventAddListSelect',val)
        //     },
        //     /**
        //      * 清空一组多选
        //      * @param ctx
        //      * @param id
        //      */
        //     delListSelect:function (ctx,id:string=''){
        //         //@ts-ignore
        //         ctx.commit('eventdelListSelect',location.href)
        //     }
        //
    },
    mutations: {

        //改变器
        /**
         * 添加多选列表
         * @param state
         * @param val
         */
        //@ts-ignore
        addListSelect: function (state, val) {
            //@ts-ignore
            state.listSelect.push(val)
        },

        /**
         * 清空一组多选
         * @param ctx
         * @param id
         */
        //@ts-ignore
        delListSelect: function (state) {
            //@ts-ignore
            state.listSelect[location.href] = []
        }
    },
    modules: {
        //计算属性
        //@ts-ignore
        getListSelect(state) {
            return state.listSelect
        }
    }
})
