import {RouteRecordRaw} from "vue-router";

const router_chang_jian_wen_ti:Array<RouteRecordRaw> = [
    {
        //进度-个人中心
        path: '/chang_jian_wen_ti/index',
        name: 'chang_jian_wen_ti_index',
        component: () => import("@/views/chang_jian_wen_ti/index.vue"),
        meta: {title: "常见问题", type: 'open'},
        children:[
            {
                path:"info/:id",
                name:'chang_jian_wen_ti_index_info',
                components:{
                    chang_jian_wen_ti_index_info: ()=>import("@/views/chang_jian_wen_ti/info.vue")
                }
            }
        ]

    }

]


export default router_chang_jian_wen_ti
