import {RouteRecordRaw} from "vue-router";
const router_user: Array<RouteRecordRaw> = [
    {
        //进度-个人中心
        path: '/user/jin_du_index',
        name: 'user_jin_du_index',
        component: () => import("@/views/user/jin_du_index.vue"),
        meta: {title: "个人中心", type: 'jinDu'}
    },
    {
        //进度-修改手机号
        path: '/user/jin_du_set_mobile',
        name: 'user_jin_du_set_mobile',
        component: () => import("@/views/user/jin_du_set_mobile.vue"),
        meta: {title: "绑定手机", type: 'jinDu'}
    },
    {
        //收集信息
        path: '/ShouJiInfo/Index',
        name: 'ShouJiInfo',
        component: () => import("@/views/ShouJiInfo/Index.vue"),
        meta: {title: "收集信息", type: 'user'}
    },
    {
        //显示企业分数详情
        path: '/ShouJiInfo/feiShuInfo',
        name: 'feiShuInfo',
        component: () => import("@/views/ShouJiInfo/feiShuInfo.vue"),
        meta: {title: "分数详情", type: 'open'}
    }
]


export default router_user
