import axios from 'axios';
import {Toast, Dialog} from "vant";
import qs from "qs"
import router from "@/router";
import {getUserInof} from "@/api/cookie";
import to from 'await-to-js'
import XEUtils from "xe-utils";


axios.defaults.timeout = 1000 * 10;
if (process.env.NODE_ENV != 'production') {
    axios.defaults.baseURL = '/api';
    // axios.defaults.headers['Content-Type'] = "application/x-www-form-urlencoded"
} else {
    axios.defaults.baseURL = '';
}

axios.din_shi_time = 2000

axios.defaults.headers['X-Requested-With'] = 'xmlhttprequest'
axios.interceptors.request.use(function (config) {
    if (typeof config == "undefined") {
        config = {}
    }
    if (typeof config.load == "undefined") {
        config.load = true
    }
    config.headers['mobile-gzh-token'] = getUserInof()?.token

    if (config.load) {
        loadUrl += 1; //加载曾
        load_show("加载中...")
    }

    if (typeof config.load_error == "undefined") {
        config.load_error = true //错误提示
    }

    if (typeof config.load_success == "undefined") {
        config.load_success = false //成功提示
    }

    if (location.hostname === 'localhost') {
        // 添加登入
        if (typeof config.params == "undefined") config.params = {}

    }
    if (typeof config.isFile == "undefined") {
        //是否上传图片
        config.isFile = false
    }

    if (config.method == "post" && !config.isFile) {
        config.data = qs.stringify(config.data)
    }

    return config;
}, function (error) {
    load_close()
    Dialog({message: "请求失败，请等会在试"});
    console.log('-1')
    return error;
})

axios.interceptors.response.use(function (response) {

    load_close()
    try {

        if (response['data'].code === 1003) {
            Toast.fail(response['data'].msg)
            router.push({name: 'UserLogin'})
            return response['data']
        }


        if (response.config.load_error && parseInt(response['data'].code) === 0) {
            //更具不同的状态 处理
            Toast.fail({
                message: response['data'].msg,
            });
        }
        if (response.config.load_success && parseInt(response['data'].code) == 1) {
            Toast.success({
                message: response['data'].msg,
            });
        }
    } catch (e) {
        console.log(e)
    }

    return response['data'];
}, function (error) {
    load_close()
    Dialog({message: "请求失败，请等会在试"});
    return error;
})

var loadUrl = 0 //统计请求的次数
var Indicator = "" //加载框
function load_close() {
    loadUrl -= 1

    if (loadUrl <= 0 && typeof Indicator == "object") {
        Indicator.clear();
        Indicator = ""
    }
}

function load_show(msg) {
    if (typeof Indicator != "object") {
        Indicator = Toast.loading({
            message: msg,
            forbidClick: true,
            duration: 30000
        });
    }

}


export async function get(url, data, config = {}) {
    config.params = data
    const [e, da] = await to(axios.get(url, config))
    return runData(da)
}

export async function post(url, data, config = {}) {
    const [e, da] = await to(axios.post(url, data, config))
    return runData(da)
}

function runData(da) {
    return new Promise((s, e) => {
        if (XEUtils.get(da, 'code') === 1) {
            s(da)
        } else if (XEUtils.get(da, 'code') === 1003) {
            //重新登入
        } else {
            const msg = XEUtils.get(da, 'msg', '请求失败，请等会在试')
            Dialog({message: msg});
            e(msg)
        }
    })
}


export default axios;
