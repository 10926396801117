import {RouteRecordRaw} from 'vue-router'

const router_gcs:Array<RouteRecordRaw> = [
    {
        //进度-个人中心
        path: '/gcs/index',
        name: 'gcs_index',
        component: () => import("@/views/gcs/index.vue"),
        meta: {title: "工程师入驻", type: 'open'}
    },
    {
        //进度-个人中心
        path: '/gcs/fuWuShangRuZhu',
        name: 'fuWuShangRuZhu',
        component: () => import("@/views/gcs/fuWuShangRuZhu.vue"),
        meta: {title: "服务商入驻", type: 'open'}
    }
]


export default router_gcs
