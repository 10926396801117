import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import router_login from "./login"
import router_user from './user'
import router_gcs from './gcs'
import chang_jian_wen_ti from './chang_jian_wen_ti'
import platform_router_list from './news'
import {getUserInof, setReturnUrl} from '@/api/cookie.js'
import XEUtils from "xe-utils";
import _ from 'lodash'
import {is_page_cache, set_page_cache} from '../api/PageCache'
import JSSDK_QYM from "qym-sdk"

let routes: Array<RouteRecordRaw> = [
    {
        //首页
        path: '/',
        name: 'Index',
        component: () => import('../views/index/Index.vue'),
        meta: {
            title: "云佐平台",
            type: ['open'],
            keepAlive: true
        }
    },
    {
        //首页
        path: '/text1',
        name: 'text1',
        component: () => import('../views/test/Test1.vue'),
        meta: {
            title: "云佐平台",
            type: ['open'],
            keepAlive: true
        }
    },
    {
        //首页
        path: '/XieYi/:id',
        name: 'XieYi',
        component: () => import('@/views/XieYi.vue'),
        meta: {title: "云佐平台", type: ['open']}
    },
    {
        path: '/Index/FuWuDaTing',
        name: 'FuWuDaTing',
        component: () => import('../views/index/FuWuDaTing.vue'),
        meta: {
            title: "服务大厅", type: ['open'],
            keepAlive: true, //开启磁芯时间缓存
        }
    },

    {
        path: '/Index/AnLiDaTing',
        name: 'AnLiDaTing',
        component: () => import('@/views/index/AnLiDaTing.vue'),
        meta: {
            title: "案例大厅",
            type: ['open'],
            keepAlive: true,//使用缓存
        }
    },
    {
        path: '/Index/XiangMuDaTing',
        name: 'XiangMuDaTing',
        component: () => import('../views/index/XiangMuDaTing.vue'),
        meta: {
            title: "项目大厅", type: ['open'],
            keepAlive: true,
        }
    },
    {
        path: '/Index/FuWuShangInfo',
        name: 'FuWuShangInfo',
        component: () => import('../views/index/FuWuShangInfo.vue'),
        meta: {title: "服务商详情", type: ['open'], keepAlive: true,}
    },
    {
        path: '/Index/FuWuShangInfoDetail',
        name: 'FuWuShangInfoDetail',
        component: () => import('../views/index/FuWuShangInfoDetail.vue'),
        meta: {title: "服务商案例详情", type: ['open']}
    },
    {
        path: '/User/JiaRuGongSi',
        name: 'JiaRuGongSi',
        component: () => import('../views/user/JiaRuGongSi.vue'),
        meta: {title: "加入公司", type: ['user']}
    },
    {
        path: '/Index/QuDaoHeZuo',
        name: 'QuDaoHeZuo',
        component: () => import('../views/index/QuDaoHeZuo.vue'),
        meta: {title: "渠道合作", type: ['open']}
    },
    {
        path: '/Index/QuanBuType',
        name: 'QuanBuType',
        component: () => import('../views/index/QuanBuType.vue'),
        meta: {title: "全部分类", type: ['open']}
    },
    {
        path: '/User/UserHome',
        name: 'UserHome',
        component: () => import('../views/user/UserHome.vue'),
        meta: {title: "个人中心", type: ['user']}
    },
    {
        path: '/User/WoChengJieDeXiangMu',
        name: 'WoChengJieDeXiangMu',
        component: () => import('../views/user/WoChengJieDeXiangMu.vue'),
        meta: {title: "我承接的项目", type: ['user']}
    },
    {
        path: '/User/YuanGongGuanLi',
        name: 'YuanGongGuanLi',
        component: () => import('../views/user/YuanGongGuanLi.vue'),
        meta: {title: "员工管理", type: ['user']}
    },
    {
        path: '/User/WoDeXuQiu',
        name: 'WoDeXuQiu',
        component: () => import('../views/user/WoDeXuQiu.vue'),
        meta: {title: "我的需求", type: ['user']}
    },
    {
        path: '/addXuQIu',
        name: 'addXuQIu',
        component: () => import('../views/index/addXuQIu.vue'),
        meta: {title: "发布需求", type: []}
    },

    {
        path: '/User/PingLun/:kan_ban_id',
        name: 'PingLun',
        component: () => import('../views/user/PingLun.vue'),
        meta: {title: "项目评价", type: ['user']}
    },
    {
        path: '/User/CustomerPages/:g_id',
        name: 'CustomerPages',
        component: () => import('../views/user/CustomerPages.vue'),
        meta: {title: "客户主页", type: ['user']}
    },
    {
        path: '/User/MessageList',
        name: 'MessageList',
        component: () => import('../views/user/MessageList.vue'),
        meta: {title: "消息中心", type: ['user']}
    },
    {
        path: '/Index/XiangMuInfo/:xiang_mu_id',
        name: 'XiangMuInfo',
        component: () => import('../views/index/XiangMuInfo.vue'),
        meta: {title: "项目详情", type: ['open']}
    },
    {
        path: '/User/AddHetong/:ywht_id',
        name: 'AddHetong',
        component: () => import('../views/user/AddHetong.vue'),
        meta: {title: "上传合同", type: ['user']}
    },
    {
        path: '/User/UserInfo',
        name: 'UserInfo',
        component: () => import('../views/user/UserInfo.vue'),
        meta: {title: "个人消息", type: ['user']}
    },
    {
        path: '/User/EditCompanyInfo',
        name: 'EditCompanyInfo',
        component: () => import('../views/user/EditCompanyInfo.vue'),
        //自动识别类型，显示对应的编辑页面
        meta: {title: "编辑信息", type: ['user']}
    },

    {
        path: '/User/CompanyInfoEdit',
        name: 'CompanyInfoEdit',
        component: () => import('../views/user/public/CompanyInfoEdit.vue'),
        //编辑公司信息
        meta: {title: "编辑信息", type: ['user']}
    },
    {
        path: '/User/UserEditInfo',
        name: 'UserEditInfo',
        component: () => import('../views/user/UserEditInfo.vue'),
        meta: {title: "编辑个人信息", type: ['user']}
    },
    {
        path: '/User/CaseList',
        name: 'CaseList',
        component: () => import('../views/user/CaseList.vue'),
        meta: {title: "案例管理", type: ['user']}
    }, {
        path: '/User/UserCaseEdit',
        name: 'UserCaseEdit',
        component: () => import('../views/user/UserCaseEdit.vue'),
        meta: {title: "操作案例", type: ['user']}
    },
    {
        path: '/User/Recommender',
        name: 'Recommender',
        component: () => import('../views/user/Recommender.vue'),
        meta: {title: "我的邀请列表", type: ['user']}
    }, {
        path: '/User/UserCompanyInfoEdit',
        name: 'UserCompanyInfoEdit',
        component: () => import('../views/user/UserCompanyInfoEdit.vue'),
        meta: {title: "编辑信息", type: ['user']}
    },
    {
        path: '/Index/AnLiInfo/:an_li_id',
        name: 'AnLiInfo',
        component: () => import('../views/index/AnLiInfo.vue'),
        meta: {title: "案例详情", type: ['open']}
    },
    {
        path: '/User/ZhuanYiFuZeRen',
        name: 'ZhuanYiFuZeRen',
        component: () => import('../views/user/ZhuanYiFuZeRen.vue'),
        meta: {title: "转移负责人", type: ['user']}
    },
    {
        path: '/navList',
        name: 'Home',
        component: import('../views/Home.vue'),
        meta: {title: "云佐平台", type: ['open']}
    },

    {
        path: '/User/WoDeXiangMu',
        name: 'WoDeXiangMu',
        component: () => import('@/views/user/WoDeXiangMu.vue'),
        meta: {title: "我的项目", type: ['user']}
    },
    {
        path: '/User/DaiPingJia',
        name: 'DaiPingJia',
        component: () => import('@/views/user/DaiPingJia.vue'),
        meta: {title: "待评价项目", type: ['user']}
    },

    {
        path: '/EditPassword',
        name: 'EditPassword',
        component: () => import('@/views/index/EditPassword.vue'),
        meta: {title: "修改密码", type: ['open']}
    },
    {
        path: '/EditMobile',
        name: 'EditMobile',
        component: () => import('@/views/index/EditMobile.vue'),
        meta: {title: "修改手机号", type: ['open']}
    },
    {
        path: '/EditMobile2',
        name: 'EditMobile2',
        component: () => import('@/views/index/EditMobile2.vue'),
        meta: {title: "修改手机号", type: ['open']}
    },

    /*------------------ 惠企政策 开始----------------------*/
    {
        path: '/policy',
        name: 'PolicyIndex',
        component: () => import('@/views/policy/index.vue'),
        meta: {title: "惠企政策", type: ['open'], keepAlive: true}
    },
    {
        path: '/policyInfo/:id/:pageType?/:yuanWenId?',
        name: 'policyInfo',
        component: () => import('@/views/policy/policyInfo.vue'),
        meta: {title: "惠企政策详情", type: ['open']}
    },
    {
        path: '/PolicyCollect',
        name: 'PolicyCollect',
        component: () => import('@/views/policy/collect.vue'),
        meta: {title: "我的收藏", type: ['user']}
    },
    /*------------------ 惠企政策 结束----------------------*/
    /*------------------ 项目立项审批全过程 开始----------------------*/
    {
        path: '/approvalProcess/:navId?/:navId2?',
        name: 'approvalProcess',
        component: () => import('@/views/policy/approvalProcess.vue'),
        meta: {title: "投资项目审批及建设", type: ['open'], keepAlive: true}
    },
    {
        path: '/approvalProcessInfo/:navMenuId',
        name: 'approvalProcessInfo',
        component: () => import('@/views/policy/approvalProcessInfo.vue'),
        meta: {title: "投资项目审批及建设", type: ['open']}
    },
    {
        path: '/shouJiInfo2/index',
        name: 'shouJiInfo2Index',
        component: () => import('@/views/shouJiInfo2/Index.vue'),
        meta: {title: "专精特新“小巨人”诊断咨询", type: ['user']}
    },
    /*------------------ 项目立项审批全过程 结束----------------------*/


    {
        //重定向
        path: "/:pathMatch(.*)*",
        component: () => import("@/views/open/404.vue"),
        meta: {title: "404", type: ['open']},
        name: 'error',
    },


]
routes = routes.concat(router_login, router_user, router_gcs, chang_jian_wen_ti, platform_router_list);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next) => {
    // window.shangYeMian = location.href //

    //企业码统计
    try {
        JSSDK_QYM.sendData({
            pageUrl: location.href,
            pageId: XEUtils.get(to, 'name', ''),
            pageName: XEUtils.get(to, 'meta.title', ''),
            appId: '6ojCLHrh'
        })
    } catch (e) {
        console.log(e)
    }
    if (to.meta.title) {

        try {
            document.title = XEUtils.toValueString(XEUtils.get(to, 'meta.title', ''));
        } catch (e) {
            console.log('修改title失败')
        }
    }
    let quan_xian: any = to.meta.type
    if (XEUtils.isUndefined(quan_xian)) quan_xian = []
    if (!XEUtils.isArray(to.meta.type)) quan_xian = quan_xian.split(',')
    if (XEUtils.includes(quan_xian, 'user')) {
        //需要登入拦截
        const userInfo = getUserInof();
        if (!userInfo) {
            setReturnUrl(to.fullPath)
            next({
                name: 'UserLogin'
            })
            return
        }
    }


    if (!_.isUndefined(_.get(to.meta, ['keepAlive']))) {
        //页面缓存是否过期 必须放在设置缓存上面
        if (is_page_cache(to.name)) {
            to.meta.keepAlive = false
        }
    }

    if (!_.isUndefined(_.get(from.meta, ['keepAlive']))) {
        //页面离开，有页面缓存
        set_page_cache(from.name)
    }

    next()
})

export default router
