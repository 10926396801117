import {RouteRecordRaw} from "vue-router";

const platform_router_list: Array<RouteRecordRaw> = [
    {
        path: '/platform/PlatformProduce',
        name: 'PlatformPlatformProduce',
        component: () => import('../views/platform/PlatformProduce.vue'),
        meta: {title: "平台介绍", type: ['open']}
    },
    {
        path: '/platform/CompanyNews',
        name: 'PlatformCompanyNews',
        component: () => import('../views/platform/CompanyNews.vue'),
        meta: {
            title: "公司新闻",
            keepAlive: true,
            type: ['open']
        }
    },
    {
        path: '/platform/NewsDetail',
        name: 'PlatformNewsDetail',
        component: () => import('../views/platform/module/NewsDetail.vue'),
        meta: {title: "公司新闻详情", type: ['open']}
    },
    {
        path: '/platform/GeneralProblem',
        name: 'PlatformGeneralProblem',
        component: () => import('../views/platform/GeneralProblem.vue'),
        meta: {title: "常见问题", type: ['open']}
    },
    {
        path: '/platform/zheng_fu_zi_xun',
        name: 'zheng_fu_zi_xun',
        component: () => import('../views/platform/zheng_fu_zi_xun.vue'),
        meta: {title: "政策资讯", type: ['open'], keepAlive: true,}
    },
    {
        path: '/platform/ZhengFuZiXunInfo/:id',
        name: 'ZhengFuZiXunInfo',
        component: () => import('../views/platform/ZhengFuZiXunInfo.vue'),
        meta: {title: "政策资讯详情", type: ['open']}
    },
    {
        path: '/platform/SpecialistLibrary',
        name: 'PlatformSpecialistLibrary',
        component: () => import('../views/platform/SpecialistLibrary.vue'),
        meta: {
            title: "专家库",
            keepAlive: true,
            type: ['open']
        }
    },
    {
        path: '/platform/SpecialistDetails',
        name: 'PlatformSpecialistDetails',
        component: () => import('../views/platform/module/SpecialistDetails.vue'),
        meta: {title: "专家详情", type: ['open']}
    },

];
export default platform_router_list
