<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component
                 :is="Component"
                 :key="$route.fullPath"
                 v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <component
               :is="Component"
               :key="$route.fullPath"
               v-if="!$route.meta.keepAlive"
    />
  </router-view>
</template>

<script>

</script>

<style lang="less">
@import "./assets/basis_css/style/var.less";
@import "./assets/icon/iconfont/iconfont.css";


/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
.prohibit-copy {
  user-select: none !important;
}

.van-popup--center {
  width: auto;
}

//阴影遮罩
.yin-ying {
  background-color: @white;
  border-radius: 10px;
  padding: 10px;
  margin: 0 10px;
  box-shadow: 0 2px 4px #ecdddd6e;

}

.i-icon {
  vertical-align: middle;
}

.bg-fff {
  background-color: @white;
}

.bg-aaa {
  background-color: @gray-2;
}

.padding-top-15 {
  padding-top: 15px;
}

.body-margin {
  padding: 0 30px;
}

.body-margin-10 {
  padding: 0 30px;
}

.bu-ju-3 {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  align-items: stretch;
  justify-content: space-between;

  .top {
  }

  .body {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .footer {
    height: 56px;
  }

}


.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
  background-color: @blue;
  border-color: @blue;
}

a {
  color: @blue;
}

.container {
  padding: 0 10px;
}

a:hover, a:visited, a:link {
  text-decoration: none;
}

//未访问：蓝色、无下划线


body {
  background: @gray-1;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-aaa {
  color: @gray-6;
}

.text-000 {
  color: @black;
}

.text-lv-s {
  color: #73c36a;
}

.text-red {
  color: @red;
}

.text-zs {
  color: @blue;
}

.lv-se {
  color: @green;
}


/*表单样式*/
.form-list {
  margin-top: 10px;
  margin-bottom: 20px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-15 {
  margin-top: 15px;
}

.dan-xuan {
  background-color: #fff;
  padding: 8px 18px;
}

.dan-xuan-title {
  width: 6.2em;
  font-size: @font-size-md;
  color: #646566;
}

.van-form {
  .van-cell {
    background-color: transparent;

  }

  .van-collapse-item__content {
    display: none !important;
  }
}

//表单下面按钮
.form-btn {
  padding: 0 20px;
  margin-top: 50px;
  padding-bottom: 20px;
  //margin-bottom: 10px;
}

//输入框上面提示文字
.input-top-title {
  padding: 5px 15px;
  font-size: @font-size-sm;
  color: #646566;
}

//输入框右边的图标文字
.input-right-icon i {
  font-size: @font-size-md;
}

/*--------- 列表--------*/

.van-list {
  border-bottom: 1px solid @gray-3;
  padding: 1.3rem 0;
}

.van-list .hang {
  padding: 6px 7px;
  font-size: @font-size-md;

}

.max-width {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto
}

i.required::before {
  position: absolute;
  left: 10px;
  color: #ee0a24;
  font-size: 14px;
  content: '*';
}

.van-field__label {
  padding-left: 11px;
}

.van-tree-select__selected {
  top: 68% !important;
}

//上传图片
.lol-uploader {

}
.van-radio__label,.van-checkbox__label{
  margin-left: 10px !important;
}

</style>
