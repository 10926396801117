import XEUtils from "xe-utils";
import http from "./http";
import {sdstorage} from "@/api/sdstorage";
import _ from 'lodash'

const UserIdName = 'fdguhfdgfdjk'


export function getUserInof() {
    let val = localStorage.getItem(UserIdName)
    if (!val) {
        return null
    } else {
        //禁止使用其他缓存参数
        val = JSON.parse(val)
        if (_.isEmpty(val)) {
            return null
        }
        return val
    }
}

//更新cookie
export async function updateUserInfo() {
    let userInfo = await http.get('/gzhphp/user/getUserInfo')
    setUserInfo(userInfo.data)
    return new Promise((s) => {
        s(userInfo)
    })
}

export function setUserInfo(val) {
    if (!XEUtils.isObject(val)) {
        return false
    }
    let status = localStorage.setItem(UserIdName, JSON.stringify(val))
    return status
}

//设置登入方式 mobile｜qi_ye_ma
export function setLoginType(val) {
    sdstorage.save('login_type', val, '10d')
}

export function getLoginType() {
    return sdstorage.get('login_type')
}


/**
 * 是否登入 true=已登入
 */
export function isLogin() {
    let data = getUserInof()
    if (XEUtils.isObject(data) && !XEUtils.isNull(data)) {
        return true
    }
    return false
}

const ReturnUrlName = 'dfkjhlgfjneriuthrgnfdjk'

/**
 * 设置返回地址
 */
export function setReturnUrl(url) {
    console.log('set url', url)
    return localStorage.setItem(ReturnUrlName, url)
}

/**
 * 获取返回地址
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getReturnUrl() {
    let url = localStorage.getItem(ReturnUrlName, url)
    if (XEUtils.isString(url)) {
        return url
    } else {
        return {name: 'UserHome'}
    }
}

//删除用户信息
export function removeUserInfo() {
    localStorage.removeItem(UserIdName)
    return http.get('/gzhphp/login/tuiChu')
}

