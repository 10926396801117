import moment from "moment";
import _ from "lodash";

let page_cache_list = {};

/**
 * 设置缓存
 */
export const set_page_cache = (page_name) => {
    if (!_.isString(page_name)) return false
    page_cache_list[page_name] = moment().unix() + 300
    return true
}
/**
 * 当前是否为缓存 true 页面缓存过期
 * @param page_name
 */
export const is_page_cache = (page_name) => {
    if (_.isUndefined(page_cache_list[page_name])) {
        return false
    }
    let val = _.toInteger(page_cache_list[page_name])
    return val < moment().unix()
}

