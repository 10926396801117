import {RouteRecordRaw} from 'vue-router'
const router_login: Array<RouteRecordRaw> = [

    {
        //微信登录
        path: '/login/jin_du_wx',
        name: 'login_jin_du_wx',
        component: () => import("@/views/login/jin_du_wx.vue"),
        meta: {title: "微信登录", type: 'open'}
    },
    {
        path: '/MobileLogin',
        name: 'MobileLogin',
        component: () => import('@/views/index/MobileLogin.vue'),
        meta: {title: "手机验证码登入", type: ['open']}
    },
    {
        path: '/UserLogin',
        name: 'UserLogin',
        component: () => import('@/views/index/UserLogin.vue'),
        meta: {title: "登入", type: ['open']}
    },
    {
        path: '/NumberLogin',
        name: 'NumberLogin',
        component: () => import('@/views/index/NumberLogin.vue'),
        meta: {title: "账号密码登入", type: ['open']}
    },
    {
        path: '/Index/AutoLogin/:token',
        name: 'AutoLogin',
        component: () => import('../views/index/AutoLogin.vue'),
        meta: {title: "自动登入", type: ['open']}
    },
    {
        path: '/Index/addMobile/:ran_anme',
        name: 'addMobile',
        component: () => import('../views/index/addMobile.vue'),
        meta: {title: "绑定手机号", type: ['open']}
    },
]


export default router_login
